const returnDate = ({ item, lang }) => {

    let date = new Date(item._createdAt);

    if(item.publishDate ) {
       date = new Date(item.publishDate);
    }
    // const date = new Date(item._createdAt);
    // test if less than a day
    const MINUTE = 1000 * 60;
    const HOUR = 1000 * 60 * 60;
    const DAY = HOUR * 24;
    const anHourAgo = Date.now() - HOUR;
    const anDayAgo = Date.now() - DAY;
    if (date > anDayAgo) {
        if (date > anHourAgo) {
            const time = Math.round(Math.abs(date - Date.now()) / MINUTE);
            if (lang === 'en') {
                return `${time} minutes ago`;
            }
            if (time === 1) {
                return `För 1 minut sedan`;
            }
            return `För ${time} minuter sedan`;
        }
        const time = Math.round(Math.abs(date - Date.now()) / HOUR);
        if (lang === 'en') {
            return `${time} hours ago`;
        }
        if (time === 1) {
            return `För 1 timme sedan`;
        }
        return `För ${time} timmar sedan`;
    }
    // todo lang
    const locale = lang === 'en' ? 'en' : 'sv-SE';
    return date.toLocaleDateString(locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
};

export default returnDate;
